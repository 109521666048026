import { createSlice } from "@reduxjs/toolkit";
import {
  loginUser,
  logoutUser,
  resetPassword,
  verifyResetPasswordToken,
} from "./userThunks";

export const LoginStatus = {
  UNKNOWN: "unknown",
  SUCCESS: "success",
  ERROR: "error",
  PENDING: "pending",
};

export const ResetPasswordStatus = {
  UNKNOWN: "unknown",
  SUCCESS: "success",
  ERROR: "error",
};

const initialState = {
  isAuthenticated: false,
  status: LoginStatus.UNKNOWN,
  message: null,
  name: "",
  resetPassword: {
    tokenStatus: ResetPasswordStatus.UNKNOWN,
    isSuccess: false,
    isLoading: false,
  },
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser: {
      reducer(state) {
        state.status = LoginStatus.UNKNOWN;
        state.message = null;
        state.name = "";
        state.isAuthenticated = false;
      },
    },
    setIsAuthenticated: {
      reducer(state, action) {
        state.isAuthenticated = action.payload;
      },
    },
    clearResetPasswordPage: {
      reducer(state) {
        state.resetPassword.tokenStatus = ResetPasswordStatus.UNKNOWN;
        state.resetPassword.isSuccess = false;
        state.resetPassword.isLoading = false;
      },
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyResetPasswordToken.fulfilled, (state) => {
        state.resetPassword.tokenStatus = ResetPasswordStatus.SUCCESS;
      })
      .addCase(verifyResetPasswordToken.rejected, (state) => {
        state.resetPassword.tokenStatus = ResetPasswordStatus.ERROR;
      })
      .addCase(resetPassword.pending, (state) => {
        state.resetPassword.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.resetPassword.isSuccess = true;
        state.resetPassword.isLoading = false;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.resetPassword.isLoading = false;
      })
      .addCase(loginUser.pending, (state) => {
        state.status = LoginStatus.PENDING;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = LoginStatus.SUCCESS;
        state.name = action.payload.name;
        state.message = null;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = LoginStatus.ERROR;
        state.message = action.payload;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.status = LoginStatus.UNKNOWN;
        state.message = null;
        state.name = "";
        state.isAuthenticated = false;
      })
      .addCase(logoutUser.rejected, (state) => {
        state.status = LoginStatus.UNKNOWN;
        state.message = null;
        state.name = "";
        state.isAuthenticated = false;
      })
      .addCase(logoutUser.pending, (state) => {
        state.status = LoginStatus.PENDING;
        state.isAuthenticated = false;
      });
  },
});

export const { resetUser, setIsAuthenticated, clearResetPasswordPage } =
  userSlice.actions;

export default userSlice.reducer;
